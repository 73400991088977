













































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Matisse from '@/assets/Matisse.svg'
import ButtonSvg from '@/assets/button.svg'
import Portant from '@/assets/Portant.svg'
import Leger from '@/assets/Leger.svg'
import Soth from '@/assets/Soth.svg'
import Bubu from '@/assets/Bubu.svg'
import Zumthor from '@/assets/Zumthor.svg'
import Andujar from '@/assets/Andujar.svg'
import Tati from '@/assets/Tati.svg'
import Ballard from '@/assets/Ballard.svg'
import GGSV from '@/assets/GGSV.svg'
import Sottsass1 from '@/assets/Sottsass1.svg'
import Sottsass2 from '@/assets/Sottsass2.svg'
import Anderson from '@/assets/Anderson.svg'
import Mahuzier from '@/assets/Mahuzier.svg'
import Perriand from '@/assets/Perriand.svg'
import Titre from '@/assets/Titre.svg'
import Bouton from '@/assets/Bouton.svg'
import Photo from '@/assets/Photo.svg'
import Brico from '@/assets/Brico.svg'
import Portemanteau from '@/assets/Portemanteau.svg'
import Group4 from '@/assets/Group4.svg'
import { appName } from '@/env'

@Component({
    components: {
        ButtonSvg,
        Matisse,
        Portant,
        Leger,
        Soth,
        Bubu,
        Zumthor,
        Andujar,
        Tati,
        Ballard,
        GGSV,
        Sottsass1,
        Anderson,
        Sottsass2,
        Mahuzier,
        Perriand,
        Titre,
        Bouton,
        Photo,
        Brico,
        Portemanteau,
        Group4,
    },
})
export default class Main extends Vue {
    public appName = appName

    public show = {
        leger: { display: false, x: 298, y: 187 },
        Soth: { display: false, x: 304, y: 402 },
        Photo: { display: false, x: 619, y: 184, image: { w: 33.73, h: 43.18, x: 585.21, y: 164.99 } },
        Brico: { display: false, x: 942, y: 404, image: { w: 33.73, h: 43.18, x: 956, y: 419 } },
        Anderson: { display: false, x: 589, y: 614 },
        Matisse: { display: false, x: 1035, y: 180 },
        Portemanteau: { display: false, x: 1287, y: 654, image: { w: 33.156, h: 43.782, x: 1287, y: 683 } },
        Sottsass1: { display: false, x: 1128, y: 742 },
        Sottsass2: { display: false, x: 737, y: 744 },
        GGSV: { display: false, x: 922, y: 744 },
        Ballard: { display: false, x: 560, y: 660 },
        Zumthor: { display: false, x: 539, y: 691 },
        Perriand: { display: false, x: 550, y: 660 },
        Andujar: { display: false, x: 550, y: 660 },
        Mahuzier: { display: false, x: 550, y: 660 },
        Tati: { display: false, x: 748, y: 613 },
        Starck: { display: false, x: 389, y: 631 },
        Titre: { display: true, x: 118, y: 50 },
        Bouton: { display: true, x: 44, y: 44 },
        Portant: { display: false, x: 51, y: 335, image: { w: 34.156, h: 37.782, x: 32, y: 420 } },
        Group4: { display: true, x: 44, y: 44 },
    }

    public dialog = false

    private descriptions = [
        {
            title: 'SOTH + GGSV',
            text:
                'Le quotidien est un point clé du travail d’Alec Soth. Dans son travail « Sleeping by the Mississippi » il se plonge dans un ordinaire instauré par ce fleuve et l’activité qui s’y est installée. Il nous invite dans l’intimité des protagonistes en les dévoilant dans leur environnement. Aujourd’hui, c’est mon quotidien que je vous présente dans cette scène, où références, livres de chevets, passions et projets en cours se mêlent. Ce décors, c’est un peu le reflet de certaines inspirations, comme par exemple du travail du studio GGSV, qui combine formes et couleurs pour une expérience graphique et ludique, parfois surréaliste appelant au rêve.',
        },
        {
            title: 'TATI + BALLARD',
            text:
                'Dans une toute autre mesure, Jacques Tati sait aussi nous plonger dans un monde onirique, mais qui a le ton de la caricature. Les usages et formes du monde moderne sont poussés à l’absurde. Le progrès questionne, il est tourné en dérision : le dénouement reste amusant pour les spectateurs des aventures de M. Hulot. James Graham Ballard nous en montre une facette plus dystopique. Dans les trois ouvrages rassemblés dans la trilogie de béton, l’aliénation de l’homme au progrès est bien plus sombre : elle le mène à sa perte, le confrontant à ses vices, à sa perversité.',
        },
        {
            title: 'PHOTO',
            text:
                'J’aime me poser la question des relations entre usages et progrès, savoir quand l’un engendre l’autre à bon ou mauvais escient. Ma façon de le comprendre, c’est d’explorer l’origine des choses pour les questionner. Je cultive depuis quelques temps une pratique photographique qui me sert de support à cette réflexion. Avec des technologies souvent rétrogrades, j’explore ce médium aussi profondément que je peux, en passant par des connaissances physiques et chimiques ou encore le démontage et la réparation d’appareils. L’image est un élément fondateur de notre culture et en comprendre les mécanismes de production c’est un petit pas vers la compréhension du monde.',
        },
        {
            title: 'ZUMTHOR',
            text:
                'Mais pour appréhender mon environnement, mes meilleurs alliés sont mes sens. C’est l’interface directe avec le monde matériel. Il y a certaines voies que j’aime explorer et celle de la matérialité en est une. En rencontrant le travail de Peter Zumthor j’ai compris son importance dans notre perception des choses. Dans son travail, elle est l’expression d’un contexte mais aussi d’un espace. Elle parle du tangible mais sait nous émouvoir. \n' +
                '\n' +
                'Ces quelques éléments exposés dans ces lignes et présents dans cette scène font partie d’un bagage qui m’a donné de goût des matériaux et des usages, une curiosité qui m’a poussé à toujours chercher à comprendre l’articulation des éléments. C’est un témoin de mes centres d’intérêt et il nourrit mes travaux.',
        },
        { image: true },
    ]
}
